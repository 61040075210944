<template>
  <div id="Policy">
    <div class="pla_part1">
      <div class="part1_content flex_col">
        <div class="top flex">
          <div class="top_content flex_col">
            <div class="title flex_col">
              <span class="title queue-bottom">惠企政策</span>
            </div>
            <div class="title_content queue-bottom">
              海量适用于深圳企业的政府扶持政策，只需企业名称，即可一键匹配专属政策。
            </div>
            <baseButton @btnAction="btnAction"/>
          </div>
        </div>
        <div class="bottom">
          <baskMask />
        </div>
      </div>
    </div>
    <div class="pla_part2">
      <div class="part2_content flex">
        <div>
          <span class="base_font queue-bottom" ref="part2_1">一站式</span>
          <span class="base_font queue-bottom" ref="part2_2">政策申报</span>
          <p class="queue-bottom" ref="part2_3">
            为企业提供及时、高效、精准、可靠的政策智能匹配和申报落地的一站式服务。
          </p>
        </div>
        <img src="@/assets/images/policy/part1.png" alt="" />
      </div>
    </div>
    <div class="pla_part3">
      <div class="part3_content flex">
        <img src="@/assets/images/policy/part2.png" alt="" />
        <div class="flex_col">
          <span class="base_font queue-bottom" ref="part3_1"
            >最新政策 实时推送</span
          >
          <div class="part3_tips">
            <span class="base_font queue-bottom" ref="part3_2"
              >实时更新各类惠企政策，帮助企业及时、全面、快速了解政策项目申报</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="pla_part4">
      <div class="part4_content flex">
        <div class="content">
          <span class="queue-bottom" ref="part4_1">1对1企业 管家服务</span>
          <p class="queue-bottom" ref="part4_2">
            在线解答及诊断评估，及时解决企业申报过程中的各种难点、疑点，助力企业做大做强。
          </p>
        </div>
        <img src="@/assets/images/policy/part3.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import baskMask from '@/common/baseMask.vue'
import baseButton from '@/common/BaseBotton.vue'

export default {
  components: {
    baskMask,
    baseButton
  },
  data () {
    return {}
  },
  computed: {},
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      if (e.target.documentElement.scrollTop >= 650) {
        if (this.$refs.part2_1 && this.$refs.part2_2 && this.$refs.part2_3) {
          this.$refs.part2_1.style.display = 'block'
          this.$refs.part2_2.style.display = 'block'
          this.$refs.part2_3.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 1550) {
        if (this.$refs.part3_1 && this.$refs.part3_2) {
          this.$refs.part3_1.style.display = 'block'
          this.$refs.part3_2.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 2350) {
        if (this.$refs.part4_1 && this.$refs.part4_2) {
          this.$refs.part4_1.style.display = 'block'
          this.$refs.part4_2.style.display = 'block'
        }
      }
    },
    btnAction () {
      this.$router.push({ path: '/policyList' })
    }
  }
}
</script>{
<style lang='scss' scoped>
#Policy {
  width: 100%;
  min-width: 1200px;
  .pla_part1 {
    padding-top: 253px;
    width: 100%;
    background: url(~assets/images/policy/bg1.png) no-repeat center center;
    background-size: cover;
    background-color: #000;
    .part1_content {
      width: 1200px;
      height: 100%;
      margin: auto;
      justify-content: space-between;
      .top {
        margin-left: 43px;
        .top_content {
          .title {
            align-items: flex-start;
            span {
              font-size: 67px;
              font-weight: bold;
              line-height: 81px;
              color: #dbbb95;
              letter-spacing: 1px;
            }
          }
          .title_content {
            text-align: left;
            margin-top: 35px;
            font-size: 26px;
            font-weight: 400;
            line-height: 39px;
            color: #ffffff;
            max-width: 512px;
            margin-bottom: 40px;
          }
        }
      }
      .bottom {
        background: #161616;
        transform: translateY(224px);
      }
    }
  }
  .pla_part2 {
    width: 100%;
    background: url(~assets/images/policy/bg2.jpg) no-repeat 50% 50%;
    background-size: cover;
    padding-top: 247px;
    .part2_content {
      width: 1200px;
      margin: auto;
      justify-content: flex-end;
      & > div {
        padding-top: 126px;
        margin-right: 52px;
        span {
          max-width: 415px;
          display: none;
        }
        p {
          margin-top: 30px;
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #ffffff;
          max-width: 438px;
          display: none;
        }
      }
    }
  }
  .pla_part3 {
    width: 100%;
    background: url(~assets/images/policy/bg3.png) no-repeat 50% 50%;
    background-size: cover;
    padding-top: 89px;
    .part3_content {
      width: 1200px;
      margin: auto;
      & > div {
        width: 486px;
        padding-top: 135px;
        padding-right: 104px;
        text-align: right;
        align-items: flex-end;
        & > span {
          display: none;
          max-width: 268px;
        }
        .part3_tips {
          margin-top: 30px;
          span {
            display: none;
            font-size: 26px;
            font-weight: 400;
            line-height: 43px;
            color: #ffffff;
            max-width: 465px;
            margin-left: -197px;
          }
        }
      }
    }
  }
  .pla_part4 {
    width: 100%;
    background: #fff;
    .part4_content {
      padding-top: 118px;
      width: 1200px;
      margin: auto;
      justify-content: flex-end;
      .content {
        padding-top: 23px;
        margin-right: -155px;
        span {
          display: none;
          font-size: 67px;
          font-weight: 400;
          line-height: 87px;
          color: #333333;
          max-width: 280px;
        }
        p {
          margin-top: 20px;
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #333333;
          max-width: 420px;
          display: none;
        }
      }
    }
  }
}
</style>
